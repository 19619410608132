import {
  Component
} from '@angular/core';
import {
  Meta
} from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SkillDna';
  constructor(private meta: Meta) {
    meta.addTag({
      name: "title",
      content: "SkillDNA guides you to improve your skill gap."
    });
    meta.addTag({
      name: 'description',
      content: 'SkillDNA is an interactive platform with a skill gap analysis that will provide you with a professional list of skills to land your dream job and enrich your resume.'
    });
    meta.addTag({
      name: "alt",
      content: "SkillDNA’s Chatbot."
    });
    meta.addTag({
      name: "alt",
      content: "The interface of SkillDNA platform that gathers user's information and analyzes it to recommend training, courses, articles and professional people to follow."
    });
    meta.addTag({
      name:"author",
      content:"iNetworks"

    });
    meta.addTag({
      name:"keywords",
      content:"skillGap,skills,courses,chatbot,Fresh graduate,career,skilldna,technical skills,job"
    });

    meta.addTag({
      property:"og:url",
      content:"https://www.skilldna.com/"
    });

    meta.addTag({
      property:"og:type",
      content:"website"
    });
    meta.addTag({
      property:"og:type",
      content:"website"
    });
    meta.addTag({
      property:"og:title",
      content:"skilldna"
    });

    meta.addTag({
      property:"og:image",
      content:"https://www.skilldna.com/assets/images/landing-V1/Illustrations/skilldnalogo.png"
    });

    meta.addTag({
      property:"og:image:width",
      content:"200"
    });

    meta.addTag({
      property:"og:image:height",
      content:"200"
    });
    
    meta.addTag({
      property:"fb:app_id",
      content:"770133990029752"
    });



    
  }


}
