import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'skilldna-landing',
    pathMatch: 'full'
  },

  {
    path: '',
    loadChildren: './skilldna-landing/skilldna-landing.module#SkilldnaLandingModule'
  },

  {
    path: 'privacy-policy',
    loadChildren: './privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  },

  {
    path: 'terms-of-service',
    loadChildren: './terms-of-service/terms-of-service.module#TermsOfServiceModule'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
